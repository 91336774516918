@import "../../assets/styles/utils";

.pages,#page ul{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @include res(margin-top,.5rem);
  a,li{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #eeeeee;
    transition: $anime-duration $anime-bezier;
    cursor: pointer;
    transition: all .4s;
    @include res(margin-left,.05rem);
    @include res(margin-right,.05rem);
    @include res(font-size, .18rem,(md:.28rem,sm:.36rem,xs:.28rem));
    @include res(width, .46rem,(md:.6rem,sm:.8rem,xs:.6rem));
    @include res(height,.46rem,(md:.6rem,sm:.8rem,xs:.6rem));
    &:hover{
      background-color: #e9e9e9;
      color: #00b0ff;
    }
    &.active,&.xl-active{
      background-color: #00b0ff;
      color: #fff;
    }
    &.xl-prevPage,&.xl-nextPage{
      display: none;
    }
    .iconfont{
      @include res(font-size,.22rem,(md:.32rem,sm:.42rem,xs:.32rem));
    }
  }
  .page_jump_text{
    @include res(margin-left,10px);
    @include res(margin-right,10px);
  }
  .page_jump_input{
    @include res(width,60px, 60 / 60);
    @include res(height,30px,30 / 30);
  }
  .page_jump_btn{
    background: none;
    border: none;
    cursor: pointer;
    transition: all;
    &:hover{
      color: $color-main;
    }
  }
}