@import "../../assets/styles/utils";

footer{
  .footer{
    background-color: $color-main;
    align-items: flex-start;
    justify-content: space-between;
    @include res(display,flex,(sm:block));
    @include res(padding-top,.9rem);
    @include res(padding-bottom,.9rem);
    .footer-info{
      flex-shrink: 0;
      @include res(width,23.84%,(sm:100%));
      @include res(margin-bottom,0,(sm:.5rem));
      .social{
        align-items: stretch;
        @include res(justify-content,flex-start,(sm:center));
        @include res(display,flex);
        @include res(margin-bottom,.45rem);
        .img{
          position: relative;
          &:not(:last-child){
            @include res(margin-right,.3rem);
          }
          &:hover{
            .iconfont{
              color: #ced4e9;
            }
            .ewm{
              opacity: 1;
              visibility: visible;
              transform: translate3d(-50%,10px,0);
            }
          }
          .iconfont{
            line-height: 1;
            transition: all .3s;
            cursor: pointer;
            color: #fff;
            @include res(font-size, .42rem,(md:.62rem,sm:.72rem,xs:.62rem));
          }
          .ewm{
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translate3d(-50%,20px,0);
            background-color: #fff;
            opacity: 0;
            visibility: hidden;
            transition: opacity .3s,visibility .3s,transform .3s;
            &::after{
              content: '';
              display: block;
              border-style: solid;
              border-color: transparent transparent #fff transparent;
              position: absolute;
              bottom: 100%;
              left: 50%;
              transform: translateX(-50%);
              @include res(border-width,.06rem);
            }
            img{
              height: auto;
              display: block;
              @include res(width,1rem,(md:100px));
            }
          }
        }
      }
      .info{
        @include res(text-align,left,(sm:center));
        .title{
          color: #fff;
          @include res(font-size,.23rem,(md:.33rem,sm:.43rem,xs:.34rem));
          @include res(margin-bottom,.3rem);
        }
        .text{
          color: rgba(206,212,233,.6);
          a{
            transition: all .3s;
            &:hover{
              color: rgba(206,212,233,1);
            }
          }
        }
      }
    }
    .footer-nav{
      flex-shrink: 0;
      @include res(width,70%,(md:72%,sm:100%));
      ul{
        align-items: stretch;
        justify-content: space-between;
        @include res(display,flex,(sm:block));
        li{
          @include res(max-width, 20%,(sm:100%));
          @include res(border-bottom,null,(sm:1px solid rgba(255,255,255,.1)));
          .title{
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #fff;
            @include res(font-size,.23rem,(md:.33rem,sm:.43rem,xs:.34rem));
            @include res(margin-bottom, .3rem,(sm:0));
            a{
              flex-grow: 1;
              @include res(margin-left,0,(sm:.1rem));
              @include res(padding-top,0,(sm:.14rem));
              @include res(padding-bottom,0,(sm:.14rem));
            }
            .iconfont{
              transition: all .3s;
              flex-shrink: 0;
              align-items: center;
              justify-content: center;
              @include res(display,none,(sm:inline-flex));
              @include res(width,1rem);
            }
          }
          .sub-nav{
            zoom: 1;
            @include res(display,block,(sm:none));
            @include res(font-size,.18rem,(md:.28rem,sm:.34rem,xs:.28rem));
            a{
              display: block;
              transition: all .3s;
              color: rgba(206,212,233,.6);
              @include res(padding-left,0,(sm:.3rem));
              @include res(padding-top,0,(sm:.1rem));
              @include res(padding-bottom,0,(sm:.1rem));
              @include res(width,auto,(sm:33.33%,xs:50%));
              @include res(float,none,(sm:left));
              &:hover{
                color: rgba(206,212,233,1);
              }
            }
            &::after{
              content: '';
              clear: both;
              display: block;
            }
          }
          &.active{
            .title{
              .iconfont{
                transform: rotate(90deg);
              }
            }
          }
        }
      }
    }
  }
  .copyright{
    background-color: $color-main-dark;
    text-align: center;
    color: #ced4e9;
    @include res(padding-top,.3rem);
    @include res(padding-bottom,.3rem);
    @include res(font-size,.18rem,(md:.24rem,sm:.3rem,xs:.26rem));
    a{
      transition: all .3s;
      &:hover{
        color: #fff;
      }
    }
  }
  .backtop{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgba(0,0,0,.3);
    color: #fff;
    position: fixed;
    z-index: 10;
    cursor: pointer;
    transition: all .3s;
    opacity: 0;
    visibility: hidden;
    @include res(width,.5rem,(md:.7rem,sm:.9rem,xs:.8rem));
    @include res(height,.5rem,(md:.7rem,sm:.9rem,xs:.8rem));
    @include res(right,.5rem,(sm:.3rem));
    @include res(bottom,.5rem);
    &:hover{
      background-color: rgba(0,0,0,.5);
    }
    .iconfont{
      line-height: 1;
      @include res(font-size,.2rem,(md:.3rem,sm:.4rem,xs:.3rem));
    }
    &.active{
      opacity: 1;
      visibility: visible;
    }
  }
  .aside-share{
    position: fixed;
    bottom: 50%;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: all .3s;
    @include res(bottom,50%);
    @include res(transform,translateY(50%));
    @include res(left,.1rem);
    .social{
      display: flex;
      flex-direction: column;
      a{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: lighter;
        transition: all .3s;
        background-color: $color-main;
        color: #fff;
        border-radius: 50%;
        @include res(width,.4rem,(md:40px));
        @include res(height,.4rem,(md:40px));
        @include res(font-size,.18rem,(md:18px));
        @include res(margin-bottom,.05rem,(md:5px));
        .iconfont{
          @include res(font-size, .2rem,(md:20px));
        }
        &:hover{
          filter: brightness(120%);
        }
        &.facebook{
          background-color: #44619D;
        }
        &.twitter{
          background-color: #55acee;
        }
        &.linkedin{
          background-color: #0077B5;
        }
        &.instagram{
          background-color: #DB10A0;
        }
        &.youtube{
          background-color: #FF021D;
        }
        &.tiktok{
          background-color: #010101;
        }
        &.whatsapp{
          background-color: #29A61A;
        }
        &.skype{
          background-color: #00ADF2;
        }
        &.email{
          background-color: #02466d;
        }
      }
    }
    .aside-share-btn{
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0,0,0,.3);
      color: #fff;
      border-radius: 50%;
      cursor: pointer;
      transition: all .3s;
      @include res(opacity,0,(md:1));
      @include res(visibility,hidden,(md:visible));
      @include res(height,.3rem,(md:30px));
      @include res(width,.3rem,(md:30px));
      @include res(transform,translateX(-50%));
      @include res(left,50%);
      @include res(top,100%);
      &:hover{
        background-color: rgba(0,0,0,.5);
      }
    }
    &.active{
      opacity: 1;
      visibility: visible;
      &:hover{
        .aside-share-btn{
          opacity: 1;
          visibility: visible;
        }
      }
    }
    &.trans{
      @include res(left,0);
      @include res(transform,translateY(50%) translateX(-100%));
      .aside-share-btn{
        opacity: 1;
        visibility: visible;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        @include res(transform,translateX(0) rotate(180deg));
        @include res(left,100%);
      }
    }
  }
}