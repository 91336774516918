@import "../../assets/styles/utils";

.ny-banner{
  position: relative;
  align-items: stretch;
  overflow: hidden;
  @include res(min-height,null,(xs:70vw));
  @include res(height,32.5vw,(sm:34vw,xs:auto));
  @include res(display,flex,(xs:block));
  .left{
    flex-shrink: 0;
    position: relative;
    box-sizing: border-box;
    color: #fff;
    z-index: 1;
    height: 100%;
    @include res(min-height,null,(xs:70vw));
    @include res(background-color,$color-main,(xs:rgba(#000,.3)));
    @include res(padding-left,1.5rem,(xs:.3rem));
    @include res(padding-right,.7rem,(xs:.3rem));
    @include res(padding-top,1.8rem,(xs:1.2rem));
    @include res(padding-bottom,0,(xs:.3rem));
    @include res(width,37.2%,(sm:45%,xs:100%));
    &::after{
      content: '';
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      left: calc(100% - 1px);
      z-index: -1;
      background-image: url(../../assets/images/banner_bg1.png);
      background-position: left center;
      background-size: auto 100%;
      background-repeat: no-repeat;
      pointer-events: none;
      @include res(display,block,(xs:none));
    }
    .banner-cont{
      width: 100%;
    }
    .banner-title{
      line-height: 1;
      white-space: nowrap;
      @include res(text-align,left,(xs:center));
      @include res(font-size,.56rem,(md:.66rem,sm:.76rem,xs:.5rem));
    }
    .banner-desc{
      @include res(margin-top, .3rem);
      @include res(font-size,.2rem,(md:.28rem,sm:.34rem,xs:.3rem));
    }
    .banner-link{
      align-items: stretch;
      white-space: nowrap;
      @include res(display,flex);
      @include res(justify-content,flex-start,(xs:center));
      @include res(margin-top,.45rem);
      @include res(font-size,.18rem,(md:.28rem,sm:.34rem,xs:.26rem));
      a{
        display: inline-block;
        background-color: rgba(235,243,248,.2);
        border-radius: 3px;
        transition: all .3s;
        @include res(padding,.1rem .2rem);
        @include res(margin-right,.05rem);
        &:hover{
          color: $color-main;
          background-color: rgba(235,243,248,1);
        }
      }
    }
    .banner-search{
      @include res(margin-top, .3rem);
      form{
        border: solid #c4e9fd;
        border-radius: 5px;
        display: flex;
        align-items: stretch;
        @include res(max-width,4.65rem,(md:100%));
        @include res(margin,null,(xs: 0 auto));
        @include res(background-color,#2b7ba8,(xs:rgba(#000,.5)));
        @include res(width, 100%,(xs:80%));
        @include res(height, .54rem,(md:.7rem,sm:.8rem,xs:.8rem));
        @include res(border-width, .01rem,(md:1px));
        input{
          flex-grow: 1;
          min-width: 100px;
          padding-left: .2rem;
          border: none;
          background: transparent;
          color: #fff;
          &::-webkit-input-placeholder{
            color: #90ccee;
          }
          &:-moz-placeholder{
            color: #90ccee;
          }
          &::moz-placeholder{
            color: #90ccee;
          }
          &:-ms-input-placeholder{
            color: #90ccee;
          }
        }
        button{
          flex-shrink: 0;
          height: 100%;
          background: transparent;
          border: none;
          color: #fff;
          cursor: pointer;
          @include res(width,.54rem,(md:.7rem,sm:.8rem,xs:.8rem));
        }
      }
    }
    .banner-icon-list{
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: flex-start;
      text-align: center;
      @include res(margin-top, .1rem,(xs:.2rem));
      @include res(margin-left,-.2rem);
      @include res(display,flex);
      .item{
        flex-shrink: 0;
        @include res(margin-top,.25rem);
        @include res(width,22%,(md:25%));
        .icon{
          border-radius: .1rem;
          border: solid #fff;
          margin: 0 auto;
          @include res(padding,.02rem,(md:2px));
          @include res(width,.82rem,(xs:1rem));
          @include res(height,.82rem,(xs:1rem));
          @include res(border-width,.02rem,(md:2px));
          >div{
            width: 100%;
            height: 100%;
            border: solid rgba(#fff,.7);
            border-radius: .07rem;
            display: flex;
            align-items: center;
            justify-content: center;
            @include res(border-width,.01rem,(md:1px));
            img{
              display: block;
              width: auto;
              height: auto;
              max-width: 90%;
              max-height: 90%;
              filter: grayscale(100%) brightness(400%) saturate(0%) contrast(200%);
            }
          }
        }
        .t{
          line-height: 1.2;
          @include res(margin-top,.1rem);
          @include res(font-size,.16rem,(md:.26rem,sm:.3rem,xs:.24rem));
        }
      }
    }
  }
  .right{
    flex-shrink: 0;
    z-index: 0;
    top: 0;
    left: 0;
    height: 100%;
    @include res(position,relative,(xs:absolute));
    @include res(width,62.8%,(sm:55%,xs:100%));
    &::after{
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.3);
      position: absolute;
      top: 0;
      left: 0;
    }
    video{
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      &.pc{
        @include res(display,block,(xs:none));
      }
      &.mob{
        @include res(display,none,(xs:block));
      }
    }
  }
  &.center{
    .left{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-top: 0;
    }
  }
  &.banner-solution{
    .left{
      @include res(padding-right,0,(xs:.3rem));
      @include res(background-color,$color-main,(xs:rgba(#000,.3)));
      @include res(width,40.6%,(md:45%,sm:50%,xs:100%));
      &::after{
        background-image: url(../../assets/images/banner_bg3.png);
      }
    }
    .right{
      @include res(width,59.4%,(md:55%,sm:50%,xs:100%));
    }
  }
}

.crumbs{
  position: absolute;
  z-index: 2;
  color: rgba(255,255,255,.6);
  @include res(top,.2rem);
  @include res(left,.2rem);
  @include res(font-size,.16rem,(md:.26rem,sm:.32rem,xs:.24rem));
  a{
    transition: all .3s;
    &:hover{
      color: #fff;
    }
  }
  span{
    @include res(margin-left,.05rem);
  }
  &.black{
    color: rgba(0,0,0,.6);
    a{
      &:hover{
        color: #000;
      }
    }
  }
}