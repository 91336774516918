@font-face {
  font-family: "iconfont"; /* Project id 4380758 */
  src: url('iconfont.woff2?t=1721801963888') format('woff2'),
       url('iconfont.woff?t=1721801963888') format('woff'),
       url('iconfont.ttf?t=1721801963888') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-twitter:before {
  content: "\e603";
}

.icon-fanhui1:before {
  content: "\e625";
}

.icon-skype:before {
  content: "\e841";
}

.icon-facebook:before {
  content: "\eab7";
}

.icon-linkedin1:before {
  content: "\eb37";
}

.icon-instagram-fill:before {
  content: "\e88f";
}

.icon-logo-whatsapp:before {
  content: "\e7ae";
}

.icon-TikTok:before {
  content: "\e6d5";
}

.icon-fanhuidingbu:before {
  content: "\e65b";
}

.icon-guanbi:before {
  content: "\e62a";
}

.icon-email3:before {
  content: "\e610";
}

.icon-skype-fill:before {
  content: "\e76f";
}

.icon-liulanliang1:before {
  content: "\e74c";
}

.icon-liebiao1:before {
  content: "\e7ce";
}

.icon-xiazai1:before {
  content: "\e65f";
}

.icon-zhuce:before {
  content: "\e63e";
}

.icon-pdf:before {
  content: "\e740";
}

.icon-shanchu:before {
  content: "\e61e";
}

.icon-tupianliebiaoqiehuan:before {
  content: "\e637";
}

.icon-dingwei:before {
  content: "\e631";
}

.icon-duihao:before {
  content: "\eaf1";
}

.icon-liulanliang:before {
  content: "\e604";
}

.icon-dianhua:before {
  content: "\e776";
}

.icon-youjian:before {
  content: "\e605";
}

.icon-bofang:before {
  content: "\e700";
}

.icon-jianzhu:before {
  content: "\e71a";
}

.icon-youtube:before {
  content: "\ec32";
}

.icon-login_user:before {
  content: "\e622";
}

.icon-linkedin:before {
  content: "\e65c";
}

.icon-tuite:before {
  content: "\e652";
}

.icon-facebook-fill:before {
  content: "\e6c7";
}

.icon-jiantou_liebiaoxiangzuo:before {
  content: "\e600";
}

.icon-jiantou_liebiaoxiangyou:before {
  content: "\e601";
}

.icon-sousuo1:before {
  content: "\e68e";
}

.icon-jiantou_liebiaoxiangyou1:before {
  content: "\e602";
}

