﻿//背景用色
$color-main:#005788; //主题色 
$color-main-dark:#02466d;
$color-main-light:#03bef6;
$color-main-bg:#f8f8f8;

//border用色
$color-line:#f2f2f2;

//文字用色
$color-red:#ff6101; //错误提示文字颜色
$color-title:#000; //标题黑色
$color-text:#333; //正文颜色  80%黑
$color-desc:#666; //解释性文字颜色  60%黑

// 动画效果持续时间
$anime-duration: 0.4s;
//贝塞尔曲线
$anime-bezier:cubic-bezier(0.77, 0, 0.175, 1);

//header高度
$header-height-base: 1.1rem;
$header-height-ratio: (md:1.4rem,sm:1.6rem,xs:1.1rem);

//内容部分左右留白
$container-gutter-base:8.125%;
$container-gutter-ratio: 6 / 8.125;

//阴影
$shadow-offset: 0px 3px;
$shadow-spread: 10px;
$shadow-color:#dbdada;

$jason-map-width: 400px !default;
$jason-map-duration: .5s !default;
$jason-map-theme: #ddd0b5 !default;

//汉堡包按钮参数设置
$hamburger-layer-width: 28px; //汉堡包按钮每一条线的宽度
$hamburger-layer-height: 3px; //汉堡包按钮每一条线的高度
$hamburger-layer-spacing: 5px; //汉堡包按钮每一条线的高度间隔
$hamburger-layer-color: $color-main; //汉堡包按钮线的颜色
$hamburger-layer-border-radius: 0; //汉堡包按钮线的圆角效果

// 响应式断点，需要从小到大写
$layout-responsive-breakpoint: ( // 手机
  xs: (min-width: 0px, container-width: 100%),
  // 平板
  sm: (min-width: 768px, container-width: 100%),
  // 小屏
  md: (min-width: 992px, container-width: 100%),
  // 中屏
  mmd: (min-width: 1025px, container-width: 100%),
  // 大屏
  lg: (min-width: 1367px, container-width: 100%),

  llg: (min-width: 1601px, container-width: 100%)) !default;
