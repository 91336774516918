@import "./utils";
@import "~jason-css/src/responsive/index";
@import "~swiper/swiper-bundle.min.css";
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@import './animate.css';
@import "../fonts/iconfont.css";

@font-face {
  font-family: 'roboto';
  src: url('../fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'roboto_b';
  src: url('../fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

*{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
}
html{
  font-size: 62.5%;
}
body {
  font-family: "roboto","Microsoft YaHei","PingFang SC";
  line-height: 1.66;
  position: relative;
  color: $color-text;
  background-color: #fff;
  @include res(font-size, 12px);
}
.comp-root{
  overflow-x: hidden;
  @include res(font-size,.18rem,(md:.28rem,sm:.34rem,xs:.3rem));
}
.bold{
  font-family: 'roboto_b';
}


//主体内容部分宽度
.container {
  @include res(padding-left,2.05rem,(md:1rem,xs:.3rem));
  @include res(padding-right,2.05rem,(md:1rem,xs:.3rem));
}
.red{
  color: $color-red;
}
.common-more{
  font-family: 'roboto_b';
  display: inline-block;
  text-transform: uppercase;
  position: relative;
  color: $color-main;
  @include res(font-size,.16rem,(md:.26rem,sm:.32rem,xs:.28rem));
  &::after{
    content: '';
    display: block;
    width: 100%;
    background-color: $color-main;
    position: absolute;
    left: 0;
    opacity: .5;
    @include res(bottom, 10%);
    @include res(height, .02rem);
  }
  &.white{
    color: #fff;
    &::after{
      background-color: #fff;
    }
  }
  &:hover{
    &::after{
      animation: line .4s linear;
    }
  }
}
@keyframes line{
  0%{
    transform: scaleX(1);
    transform-origin: right;
  }
  50%{
    transform: scaleX(0);
    transform-origin: right;
  }
  51%{
    transform: scaleX(0);
    transform-origin: left;
  }
  100%{
    transform: scaleX(1);
    transform-origin: left;
  }
}
.common-more-btn{
  display: inline-block;
  background-color: $color-main;
  text-transform: uppercase;
  border-radius: 6px;
  transition: all .3s;
  color: #fff;
  font-family: 'roboto_b';
  @include res(padding, .15rem .35rem);
  @include res(font-size,.16rem,(md:.26rem,sm:.36rem,xs:.24rem));
  &:hover{
    background-color: $color-main-light;
  }
}

.cont-mask{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.3);
  top: 0;
  left: 0;
  z-index: 100;
  display: none;
}

.ny-title{
  // color: #000;
  color: transparent;
  pointer-events: none;
  user-select: none;

  font-family: 'roboto_b';
  @include res(text-align,left,(xs:center));
  @include res(font-size,.46rem,(md:.56rem,sm:.66rem,xs:.5rem));
  // @include res(padding-top,1.2rem,(xs:.8rem));
  // @include res(padding-bottom,.6rem,(xs:.5rem));
  @include res(padding-bottom,.4rem);
}

// 列表中图片有占位图片的
.haszw-img{
  position: relative;
  overflow: hidden;
  img{
    display: block;
    width: 100%;
    height: auto;
    opacity: 0;
  }
  b,a{
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    transition: all .3s;
  }
}

.swiper-btn{
  transition: opacity .3s;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  z-index: 2;
  pointer-events: none;
  @include res(height, .6rem,(md:50px));
  @include res(opacity,0,(md:1));
  .swiper-button-next,.swiper-button-prev{
    border-radius: 50%;
    background-color: rgba(0,0,0,.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
    margin: 0;
    top: 0;
    pointer-events: all;
    @include res(width, .6rem,(md:50px));
    @include res(height, .6rem,(md:50px));
    &::after{
      font-family: 'iconfont';
      color: #fff;
      transition: all .3s;
      @include res(font-size,.24rem,(md:24px));
    }
    &:not(.swiper-button-disabled):hover{
      background-color: rgba(0,0,0,.8);
    }
    &.swiper-button-disabled{
      pointer-events: all;
    }
  }
  .swiper-button-next{
    &::after{
      content: '\e600';
    }
  }
  .swiper-button-prev{
    &::after{
      content: '\e601';
    }
  }
}

.swiper-pagination{
  z-index: 2;
  .swiper-pagination-bullet{
    opacity: 1;
    background-color: rgba(211,211,211,1);
    transition: all .3s;
    margin: 0 5px;
    cursor: pointer;
    @include res(width,.08rem,(md:8px));
    @include res(height,.08rem,(md:8px));
    &.swiper-pagination-bullet-active{
      background-color: #03bef6;
    }
  }
}

//图文切换按钮
.check-btn{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .switch-text{
    @include res(margin-right,.2rem);
    @include res(font-size,.16rem,(md:.26rem,sm:.36rem,xs:.24rem));
  }
  .switch-btn{
    display: inline-flex;
    align-items: stretch;
    div{
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-style: solid;
      border-color: #c8c9c7;
      cursor: pointer;
      color: #c8c9c7;
      transition: all .3s;
      @include res(border-width,.01rem,(md:1px)); 
      @include res(width,.36rem,(md:.6rem,sm:.7rem,xs:.6rem));
      @include res(height,.36rem,(md:.6rem,sm:.7rem,xs:.6rem));
      .iconfont{
        @include res(font-size,.22rem,(md:.34rem,sm:.42rem,xs:.34rem));
      }
      &:nth-child(1){
        border-color: $color-main;
        background-color: $color-main;
        color: #fff;
      }
    }
    &.show-text-list{
      div{
        &:nth-child(1){
          border-color: #c8c9c7;
          background-color: transparent;
          color: #c8c9c7;
        }
        &:nth-child(2){
          border-color: $color-main;
          background-color: $color-main;
          color: #fff;
        }
      }
    }
  }
}

//表单
.common-form{
  .form-flex{
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    @include res(display,flex,(xs:block));
    .form-item,.form-btn{
      flex-shrink: 0;
      @include res(width,49%,(xs:100%));
    }
  }
  .form-item{
    position: relative;
    @include res(margin-bottom, .2rem,(md:.25rem,sm:.3rem));
    .label{
      display: block;
      color: #000;
      @include res(margin-bottom,.05rem);
    }
    &:not(.form-agree){
      label{
        position: absolute;
        top: 100%;
        left: 0;
        color: $color-red;
        @include res(font-size,.14rem,(md:.24rem,sm:.3rem,xs:.24rem));
      }
    }
    
    input:not([type = checkbox]){
      width: 100%;
      border-style: solid ;
      border-color: #bfbfbf;
      border-radius: .09rem;
      color: #000;
      transition: all .3s;
      display: block;
      @include res(padding, 0 .15rem);
      @include res(border-width, .01rem,(md:1px));
      @include res(height,.6rem,(md:.75rem,sm:1.1rem,xs:.9rem));
      @include res(font-size,.2rem,(md:.28rem,sm:.34rem,xs:.3rem));
      &:disabled,&:read-only{
        background-color: #e5e5e5;
        border-color: #e5e5e5;
      }
      &:focus{
        border-color: $color-main-light;
      }
      &.error{
        border-color: $color-red;
      }
    }
    .phone-select{
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      @include res(width,.8rem,(md:1.2rem,sm:1.6rem,xs:1.2rem));
      @include res(height,.6rem,(md:.75rem,sm:1.1rem,xs:.9rem));
      &.active{
        .select-title{
          .iconfont{
            transform: translateY(-50%) rotate(180deg);
          }
        }
        .select-ul{
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        }
      }
      .select-title{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: .15rem;
        cursor: pointer;
        position: relative;
        @include res(padding-right,.1rem,(md:.2rem,sm:.4rem,xs:.2rem));
        span{
          display: block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .iconfont{
          display: block;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          @include res(font-size,.26rem,(md:.36rem,sm:.46rem,xs:.34rem));
        }
      }
      .select-ul{
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #fff;
        border-style: solid ;
        border-color: #bfbfbf;
        border-radius: .09rem;
        min-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        overflow-y: auto;
        transform: translateY(.05rem);
        opacity: 0;
        visibility: hidden;
        transition: all .3s;
        @include res(max-height,2rem,(md:2.6rem,sm:3.2rem));
        @include res(font-size,.16rem,(md:.26rem,sm:.32rem,xs:.28rem));
        @include res(border-width, .01rem,(md:1px));
        &::-webkit-scrollbar {
          width: 3px;    
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
          background: rgba(#BDBDBD,.3);
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px rgba(0,0,0,0);
          border-radius: 0;
          background: rgba(0,0,0,0);
        }
        li{
          cursor: pointer;
          transition: all .3s;
          @include res(padding, 0 .15rem);
          &:hover{
            background-color: $color-main-light;
            color: #fff;
          }
          &.active{
            background-color: $color-main;
            color: #fff;
          }
        }
      }
      +input{
        @include res(padding-left,.9rem,(md:1.4rem,sm:1.8rem,xs:1.2rem));
      }
    }
    .btn{
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;
      padding: 0 .15rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: $color-main;
      transition: all .3s;
      @include res(height,.6rem,(md:.75rem,sm:1.1rem,xs:.9rem));
      &.send-code{
        &:hover .t{
          @include res(color,$color-main-light);
        }
        .t{
          opacity: 1;
        }
        .num{
          opacity: 0;
        }
      }
      &::after{
        content: '';
        display: block;
        background-color: #bfbfbf;
        position: absolute;
        top: 30%;
        left: 0;
        @include res(width,.01rem,(md:1px));
        @include res(height,40%);
      }
      .t{
        opacity: 0;
        transition: color .3s;
      }
      .num{
        opacity: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-red;
      }
    }
    .checkbox{
      display: inline-block;
      position: relative;
      input[type = checkbox]{
        visibility: hidden;
        @include res(width,.17rem,(md:15px));
        @include res(height,.17rem,(md:15px));
        &+div{
          display: inline-flex;
          border-radius: .05rem;
          background: #e0e8eb;
          position: absolute;
          left: 0;
          top: 20%;
          align-items: center;
          justify-content: center;
          @include res(width,.17rem,(md:15px));
          @include res(height,.17rem,(md:15px));
        }
        &:checked+div::before{
          content: '\eaf1';
          font-family: 'iconfont';
          color: $color-red;
        }
      }
    }
    textarea{
      width: 100%;
      border-style: solid ;
      border-color: #bfbfbf;
      border-radius: .09rem;
      color: #000;
      transition: all .3s;
      display: block;
      @include res(padding, .15rem);
      @include res(border-width, .01rem,(md:1px));
      @include res(font-size,.2rem,(md:.28rem,sm:.34rem,xs:.3rem));
      &:focus{
        border-color: $color-main-light;
      }
      &.error{
        border-color: $color-red;
      }
    }
    &.form-agree{
      a{
        color: $color-red;
      }
      .checkbox{
        input[type = checkbox]{
          &+div{
            background: #fff;
          }
        }
      }
    }
    &.select{
      &::after{
        content: '\e602';
        font-family: 'iconfont';
        position: absolute;
        bottom: 0;
        color: $color-main;
        pointer-events: none;
        @include res(line-height,.6rem,(md:.75rem,sm:1.1rem,xs:.9rem));
        @include res(right,.2rem);
        @include res(font-size,.22rem,(md:.32rem,sm:.42rem,xs:.3rem));
      }
      input{
        cursor: pointer;
        &:disabled,&:read-only{
          background-color: #fff;
          border-color: #bfbfbf;
        }
      }
      .select-list{
        position: absolute;
        width: 100%;
        top: 105%;
        left: 0;
        z-index: 5;
        background-color: rgba(255,255,255,.6);
        border: solid $color-main-light;
        border-radius: .09rem;
        backdrop-filter: blur(10px);
        overflow-x: hidden;
        overflow-y: auto;
        transition: all .3s;
        opacity: 0;
        visibility: hidden;
        transform: translateY(5px);
        @include res(max-height,5rem);
        @include res(border-width,.01rem,(md:1px));
        li{
          transition: all .3s;
          cursor: pointer;
          @include res(padding-left,.3rem);
          @include res(padding-right,.3rem);
          @include res(padding-top,.05rem,(md:.1rem));
          @include res(padding-bottom,.05rem,(md:.1rem));
          @include res(font-size,.18rem,(md:16px,sm:14px,xs:.28rem));
          &:hover{
            color: $color-main;
          }
          &.on{
            background-color: $color-main;
            color: #fff;
          }
        }
      }
      &.hover{
        .select-list{
          opacity: 1;
          visibility: visible;
          transform: translateY(0px);
        }
      }
    }
    &.captcha{
      .captcha-img{
        position: absolute;
        top: 1px;
        right: 1px;
        height: calc(100% - 2px);
        display: flex;
        align-items: center;
        cursor: pointer;
        img{
          display: block;
          width: auto;
          border-radius: .09rem;
          @include res(height,100%,(sm:100%));
        }
      }
    }
  }
  .form-btn{
    @include res(margin-top,.25rem,(xs:.35rem));
    .label{
      display: block;
      color: #000;
      @include res(margin-bottom,.05rem);
    }
    button,.button{
      width: 100%;
      border-radius: .09rem;
      border: none;
      color: #fff;
      background-color: $color-main;
      transition: all .3s;
      cursor: pointer;
      font-family: 'roboto_b';
      display: flex;
      align-items: center;
      justify-content: center;
      @include res(height,.6rem,(md:.75rem,sm:1.1rem,xs:.9rem));
      @include res(font-size,.2rem,(md:.3rem,sm:.4rem,xs:.32rem));
      &:hover{
        background-color: #056eaa;
      }
      .iconfont{
        @include res(margin-left,.05rem);
        @include res(font-size,.24rem,(md:.34rem,sm:.44rem,xs:.36rem));
      }
    }
  }
  &.white-border{
    .form-item{
      input:not([type = checkbox]){
        border-color: #fff;
        &:disabled{
          background-color: #e5e5e5;
          border-color: #e5e5e5;
        }
        &:focus{
          border-color: $color-main-light;
        }
        &.error{
          border-color: $color-red;
        }
      }
      textarea{
        border-color: #fff;
        &:focus{
          border-color: $color-main-light;
        }
        &.error{
          border-color: $color-red;
        }
      }
    }
  }
}

// 产品列表
.pro-list{
  &:not(.text-list-show){
    ul{
      align-items: stretch;
      justify-content: flex-start;
      flex-wrap: wrap;
      @include res(display,flex);
      @include res(width,calc(100% + .45rem),(xs:calc(100% + .2rem)));
      li{
        flex-shrink: 0;
        background-color: #fff;
        border-radius: .12rem;
        transition: box-shadow .3s;
        @include res(margin-right, .45rem,(xs:.2rem));
        @include res(margin-bottom, .4rem,(xs:.2rem));
        @include res(width, calc(33.33% - .45rem),(sm:calc(50% - .45rem),xs:calc(50% - .2rem)));
        &:hover{
          box-shadow: 0 0 .3rem rgba(0,0,0,.15);
          .item-text{
            transform: translateY(0);
          }
          .item-link{
            opacity: 1;
            transform: translateY(0);
          }
        }
        a{
          display: block;
          @include res(padding,.25rem);
          @include res(padding-bottom,.45rem,(xs:.3rem));
        }
        .item-img{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          @include res(height,16.2vw,(md:19vw,sm:30vw,xs:28vw));
          img{
            display: block;
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
          }
        }
        .item-text{
          transition: all .3s;
          display: flex;
          flex-direction: column-reverse;
          justify-content: flex-start;
          align-items: center;
          @include res(margin-top,.15rem);
          @include res(margin-bottom,.15rem);
          @include res(transform,translateY(30%),(md:translateY(0)));
          .title{
            text-align: center;
            color: #000;
            transition: all .3s;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            @include res(margin-top,.1rem,(xs:.05rem));
            @include res(font-size,.25rem,(md:.35rem,sm:.44rem,xs:.3rem));
          }
          .desc{
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            @include res(font-size,.16rem,(md:.26rem,sm:.34rem,xs:.24rem));
            @include res(height,.53rem,(md:.85rem,sm:1.1rem,xs:.73rem));
          }
        }
        .item-link{
          text-align: center;
          transition: all .3s;
          @include res(opacity,0,(md:1));
          @include res(transform,translateY(70%),(md:translateY(0)));
          span{
            display: inline-block;
            background-color: $color-main;
            color:#fff;
            border-radius: .06rem;
            font-family: 'roboto_b';
            text-transform: uppercase;
            transition: all .3s;
            @include res(font-size,.16rem,(md:.24rem,sm:.32rem,xs:.22rem));
            @include res(padding,.1rem .35rem,(sm:.15rem .35rem,xs:.1rem .3rem));
            &:hover{
              background-color: $color-main-light;
            }
          }
        }
      }
    }
  }
  &.text-list-show{
    ul{
      li{
        background-color: #fff;
        border-radius: .12rem;
        transition: box-shadow .3s;
        @include res(margin-bottom, .4rem,(xs:.2rem));
        &:hover{
          box-shadow: 0 0 .3rem rgba(0,0,0,.15);
          .item-text{
            transform: translateY(0);
          }
          .item-link{
            opacity: 1;
            transform: translateY(0);
          }
        }
        a{
          display: flex;
          align-items: center;
          justify-content: space-between;
          @include res(padding-right,1rem,(xs:.2rem));
          @include res(padding-left,.5rem,(xs:.2rem));
          @include res(padding-top,.3rem,(xs:.1rem));
          @include res(padding-bottom,.3rem,(xs:.1rem));
        }
        .item-img{
          display: flex;
          align-items: center;
          justify-content: center;
          @include res(width,31%,(xs:35%));
          @include res(height,16.2vw,(md:19vw,xs:22vw));
          img{
            display: block;
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
          }
        }
        .item-text{
          transition: all .3s;
          @include res(width,40%,(xs:60%));
          @include res(margin-left,.5rem,(xs:0));
          .title{
            color: #000;
            transition: all .3s;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            @include res(margin-bottom,.1rem,(xs:.05rem));
            @include res(font-size,.25rem,(md:.35rem,sm:.44rem,xs:.3rem));
          }
          .desc{
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            @include res(font-size,.16rem,(md:.26rem,sm:.34rem,xs:.24rem));
            @include res(height,.53rem,(md:.85rem,sm:1.1rem,xs:.73rem));
          }
        }
        .item-link{
          transition: all .3s;
          @include res(display,null,(xs:none));
          span{
            display: inline-block;
            background-color: $color-main;
            color:#fff;
            border-radius: .06rem;
            font-family: 'roboto_b';
            text-transform: uppercase;
            transition: all .3s;
            @include res(font-size,.16rem,(md:.24rem,sm:.32rem,xs:.24rem));
            @include res(padding,.1rem .35rem,(sm:.15rem .35rem));
            &:hover{
              background-color: $color-main-light;
            }
          }
        }
      }
    }
  }
}

//行业列表
.industries-list{
  ul{
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    @include res(display,flex,(xs:block));
    @include res(width,calc(100% + .56rem),(xs:100%));
    li{
      flex-shrink: 0;
      border-radius: .15rem;
      overflow: hidden;
      background-color: #e1eaee;
      transition: box-shadow .3s,background-color .3s;
      @include res(width, calc(33.33% - .56rem),(sm:calc(50% - .56rem),xs:100%));
      @include res(margin-right,.56rem,(xs:0));
      @include res(margin-bottom,.5rem,(xs:.3rem));
      &:hover{
        box-shadow: 0 0 .3rem rgba(0,0,0,.15);
        background-color: #f2f8fa;
        .item-img b{
          transform: scale(1.05);
        }
        .item-text .title{
          color: $color-main;
        }
      }
      .item-img{
        &::after{
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,.2);
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .item-text{
        @include res(padding,.3rem .4rem);
        .title{
          color: #000;
          transition: all .3s;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 1.2;
          @include res(margin-bottom,.15rem);
          @include res(font-size,.28rem,(md:.38rem,sm:.48rem,xs:.4rem));
        }
        .desc{
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          line-height: 1.55;
          color: $color-desc;
          @include res(height,.83rem,(md:1.3rem,sm:1.6rem,xs:1.4rem));
          @include res(margin-bottom,.35rem);
        }
      }
    }
  }
}

//视频列表
.video-list{
  ul{
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    @include res(display,flex,(xs:block));
    li{
      flex-shrink: 0;
      background-color: #edeff3;
      box-sizing: border-box;
      border-radius: .18rem;
      cursor: pointer;
      text-align: center;
      transition: box-shadow .3s,background-color .3s;
      @include res(width,48%,(xs:100%));
      @include res(margin-bottom,.6rem,(xs:.3rem));
      a{
        display: block;
        @include res(padding, .35rem);
      }
      &:hover{
        box-shadow: 0 0 .3rem rgba(0,0,0,.15);
        background-color: #f2f8fa;
        .item-img{
          b{
            transform: scale(1.05);
          }
          .icon{
            background-color: rgba(#000,.15);
          }
        } 
        .item-t{
          color: $color-main;
        }
      }
      .item-img{
        .icon{
          width: 100%;
          height: 100%;
          background-color: rgba(#000,.3);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #cfd7e1;
          transition: background-color .3s;
          .iconfont{
            @include res(font-size,.66rem,(md:.76rem,sm:.86rem,xs:.8rem));
          }
        }
      }
      .item-t{
        color: #000;
        line-height: 1.2;
        transition: color .3s;
        @include res(margin-top,.3rem);
        @include res(font-size,.24rem,(md:.34rem,sm:.44rem,xs:.34rem));
      }
    }
  }
}

//下载列表
.common-download{
  background-color: #f4f5f8;
  border-radius: .12rem;
  @include res(padding-top,.45rem,(xs:0));
  @include res(padding-bottom,.4rem,(xs:0));
  .list-title{
    color: #000;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    border-bottom: solid #d3d3d3;
    @include res(border-bottom-width,.01rem,(md:1px));
    @include res(display,flex,(xs:none));
    @include res(padding-bottom,.15rem);
    @include res(margin-bottom,.15rem);
    .title{
      flex-shrink: 0;
      box-sizing: border-box;
      @include res(width,50%);
      @include res(padding-left,.5rem);
    }
    .right{
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      text-align: center;
      @include res(width,45%);
      @include res(padding-right,.5rem);
      >div{
        &:nth-child(1){
          text-align: left;
          @include res(width,.7rem);
        }
        &:nth-child(2){
          @include res(width,.9rem);
        }
        &:nth-child(3){
          @include res(width,1.3rem);
        }
        &:nth-child(4){
          @include res(width,.85rem,(md:1.2rem));
        }
      }
    }
  }
  .download-list{
    ul{
      li{
        align-items: center;
        justify-content: space-between;
        @include res(display,flex,(xs:block));
        @include res(padding-top,.1rem,(md:.15rem,sm:.2rem,xs:.35rem));
        @include res(padding-bottom,.1rem,(md:.15rem,sm:.2rem,xs:.2rem));
        @include res(padding-left,0,(xs:.3rem));
        @include res(padding-right,0,(xs:.3rem));
        &:nth-child(even){
          background-color: #edeff3;
        }
        .title{
          flex-shrink: 0;
          box-sizing: border-box;
          line-height: 1.2;
          @include res(font-family,null,(xs:'roboto_b'));
          @include res(width,50%,(xs:100%));
          @include res(margin-bottom,0,(xs:.2rem));
          @include res(padding-left,.5rem,(xs:0));
        }
        .right{
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          text-align: center;
          white-space: nowrap;
          @include res(width,45%,(xs:100%));
          @include res(padding-right,.5rem,(xs:0));
          >div{
            &:nth-child(1){
              text-align: left;
              @include res(width,.7rem,(xs:1rem));
            }
            &:nth-child(2){
              @include res(width,.9rem);
            }
            &:nth-child(3){
              @include res(width,1.3rem);
            }
            &:nth-child(4){
              @include res(width,.85rem,(md:1.2rem,xs:.6rem));
              a{
                display: inline-flex;
                border-radius: 50%;
                background-color: #404040;
                align-items: center;
                justify-content: center;
                color: #fff;
                overflow: hidden;
                transition: all .3s;
                @include res(width,.3rem,(md:.6rem,sm:.8rem,xs:.6rem));
                @include res(height,.3rem,(md:.6rem,sm:.8rem,xs:.6rem));
                .iconfont{
                  line-height: 1;
                  @include res(font-size,.2rem,(md:.4rem,sm:.5rem,xs:.4rem));
                }
                &:hover{
                  background-color: $color-main-light;
                }
              }
            }
          }
        }
      }
    }
  }
}

//新闻列表
.list-news{
  ul{
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    @include res(display,flex,(xs:block));
    @include res(width,calc(100% + .35rem),(xs:100%));
    li{
      flex-shrink: 0;
      @include res(width,calc(33.33% - .35rem),(sm:calc(50% - .35rem),xs:100%));
      @include res(margin-right,.35rem,(xs:0));
      @include res(margin-bottom,.7rem);
      &:hover{
        .item-img b{
          transform: scale(1.05);
        }
        .item-text .title{
          color: $color-main;
        }
      }
      .item-img{
        border-radius: .15rem;
        overflow: hidden;
      }
      .item-text{
        .date{
          @include res(margin-top,.25rem);
          @include res(margin-bottom,.15rem);
          span{
            display: inline-block;
            border-radius: 50px;
            background-color: #e5edf1;
            color: $color-desc;
            @include res(padding,.06rem .25rem);
            @include res(font-size, .16rem,(md:.24rem,sm:.34rem,xs:.26rem));
          }
        }
        .title{
          color: #000;
          transition: color .3s;
          line-height: 1.25;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          @include res(font-size,.24rem,(md:.34rem,sm:.44rem,xs:.36rem));
        }
      }
    }
  }
}