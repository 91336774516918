@import "../../assets/styles/utils";

header{
  .header-zw{
    transition: all .4s;
    @include res(height,$header-height-base,$header-height-ratio);
  }
  .header{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    z-index: 1000;
    width: 100%;
    transition: all .4s;
    background-color: #fff;
    box-shadow: 0 0 .2rem rgba(0,0,0,.1);
    @include res(top,0);
    @include res(height,$header-height-base,$header-height-ratio);
    &.scroll{
      @include res(height,.8rem,$header-height-ratio);
      .logo{
        img{
          @include res(height,.4rem,(md:.66rem,sm:.76rem,xs:.51rem));
        }
      }
    }
    &.trans{
      transform: translateY(-100%);
    }
    .logo{
      position: absolute;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      @include res(left,.6rem,(xs:.2rem));
      img{
        display: block;
        width: auto;
        transition: all .4s;
        @include res(height,.51rem,(md:.66rem,sm:.76rem,xs:.51rem));
      }
    }
    .header-nav{
      height: 100%;
      align-items: center;
      justify-content: center;
      @include res(display,flex,(md:none));
      li{
        height: 100%;
        .nav-title{
          height: 100%;
          position: relative;
          @include res(font-size,.22rem);
          &::after{
            content: '';
            display: block;
            background-color: $color-main;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            transition: all .3s;
            @include res(height,.03rem,(md:2px));
            @include res(width,0);
          }
          a{
            height: 100%;
            display: flex;
            align-items: center;
            color: #000;
            @include res(padding-left,.25rem);
            @include res(padding-right,.25rem);
          }
        }
        &:not(:nth-child(-n+2)){
          position: relative;
          .sub-nav{
            position: absolute;
            top: 100%;
            left: 0;
            background-color: #f8f8f8;
            white-space: nowrap;
            // box-shadow: 0 0 .2rem rgba(0,0,0,.1);
            transition: all .3s;
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            font-family: 'roboto_b';
            @include res(font-size,.2rem);
            @include res(min-width,2rem);
            @include res(padding, .3rem 0);
            a{
              display: block;
              transition: all .3s;
              color: $color-main;
              @include res(padding, .05rem .3rem);
              &:hover{
                color: $color-main-light;
              }
            }
          }
        }
        &:nth-child(1),&:nth-child(2){
          .sub-nav{
            position: absolute;
            top: 100%;
            left: 0;
            background-color: #f8f8f8;
            // box-shadow: 0 .05rem .2rem rgba(0,0,0,.1);
            transition: all .3s;
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            box-sizing: border-box;
            @include res(padding-top,.35rem);
            @include res(padding-bottom,.5rem);
            @include res(padding-left,3.2rem);
            @include res(padding-right,3.2rem);
            @include res(width,100%);
            ul{
              align-items: flex-start;
              justify-content: space-between;
              @include res(display,flex);
              li{
                flex-grow: 1;
                &:not(:last-child){
                  @include res(margin-right,.8rem);
                }
                .bold{
                  @include res(margin-bottom,.05rem);
                  @include res(font-size,.2rem);
                  a{
                    color: $color-main;
                    transition: all .3s;
                    &:hover{
                      color: $color-main-light;
                    }
                  }
                }
                .sub-link{
                  @include res(margin-top,.1rem);
                  @include res(font-size,.16rem,(md:.24rem,sm:.3rem,xs:.26rem));
                  a{
                    display: block;
                    transition: all .3s;
                    @include res(margin-top,.05rem);
                    &:hover{
                      color: $color-main-light;
                    }
                  }
                }
              }
            }
          }
        }
        &:nth-child(2){
          .sub-nav{
            @include res(padding-left,1.8rem);
            @include res(padding-right,1.8rem);
          }
        }
        &.hover{
          // .nav-title{
          //   &::after{
          //     @include res(width,.8rem);
          //   }
          // }
          .sub-nav{
            opacity: 1;
            visibility: visible;
            pointer-events: all;
          }
        }
      }
    }
    .header-right{
      position: absolute;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @include res(right,.6rem,(xs:.2rem));
      .worldwide{
        position: relative;
        &:hover{
          .worldwide-cont{
            opacity: 1;
            visibility: visible;
          }
        }
        .title{
          color: #000;
          cursor: pointer;
          @include res(font-size,.18rem,(md:.28rem,sm:.34rem,xs:.26rem));
          .iconfont{
            color: $color-main;
            @include res(margin-left,-.05rem);
            @include res(font-size,.2rem,(md:.3rem,sm:.4rem,xs:.3rem));
          }
        }
        .worldwide-cont{
          position: absolute;
          top: 100%;
          right: 0;
          z-index: 1;
          border: solid #bdbdbd;
          background-color: #fff;
          white-space: nowrap;
          border-radius: 5px;
          opacity: 0;
          visibility: hidden;
          transition: all .3s;
          overflow: hidden;
          @include res(border-width,.01rem,(md:1px));
          @include res(font-size,.16rem,(md:.26rem,sm:.32rem,xs:.28rem));
          a{
            display: block;
            line-height: 1.2;
            transition: background-color .3s;
            @include res(padding-left,.17rem);
            @include res(padding-right,.17rem);
            @include res(padding-top,.1rem);
            @include res(padding-bottom,.1rem);
            &:hover{
              background-color: #e9e9e9;
            }
          }
        }
      }
      .language{
        position: relative;
        @include res(margin-left,.2rem,(xs:.1rem));
        &:hover{
          .lan-cont{
            opacity: 1;
            visibility: visible;
          }
        }
        .lan-title{
          color: #000;
          cursor: pointer;
          @include res(font-size,.18rem,(md:.28rem,sm:.34rem,xs:.26rem));
          .iconfont{
            color: $color-main;
            @include res(margin-left,-.05rem);
            @include res(font-size,.2rem,(md:.3rem,sm:.4rem,xs:.3rem));
          }
        }
        .lan-cont{
          position: absolute;
          top: 100%;
          right: 0;
          z-index: 1;
          border: solid #bdbdbd;
          background-color: #fff;
          white-space: nowrap;
          border-radius: 5px;
          opacity: 0;
          visibility: hidden;
          transition: all .3s;
          overflow: hidden;
          @include res(border-width,.01rem,(md:1px));
          @include res(font-size,.16rem,(md:.26rem,sm:.32rem,xs:.28rem));
          a{
            display: block;
            line-height: 1.2;
            transition: background-color .3s;
            @include res(padding-left,.17rem);
            @include res(padding-right,.17rem);
            @include res(padding-top,.1rem);
            @include res(padding-bottom,.1rem);
            &:hover{
              background-color: #e9e9e9;
            }
          }
        }
      }
      .header-search{
        position: relative;
        &.active{
          .search-btn{
            .iconfont{
              color: $color-main;
              &.icon-sousuo1{
                display: none;
              }
              &.icon-guanbi{
                display: inline-block;
              }
            }
          }
          .search-form{
            opacity: 1;
            visibility: visible;
          }
        }
        .search-btn{
          cursor: pointer;
          @include res(margin-left,.15rem,(xs:.05rem));
          @include res(padding,0 .1rem,(sm:0 .2rem,xs:0 .1rem));
          &:hover{
            .iconfont{
              color: $color-main;
            }
          }
          .iconfont{
            transition: all .3s;
            @include res(font-size,.22rem,(md:.32rem,sm:.42rem,xs:.34rem));
            &.icon-guanbi{
              display: none;
            }
          }
        }
        .search-form{
          position: absolute;
          right: 0;
          top: 100%;
          border: solid #bdbdbd;
          background-color: #fff;
          border-radius: 5px;
          opacity: 0;
          visibility: hidden;
          transition: all .3s;
          overflow: hidden;
          @include res(border-width,.01rem,(md:1px));
          form{
            display: flex;
            align-items: stretch;
            input{
              flex-shrink: 0;
              background: transparent;
              border: none;
              @include res(padding-left,.2rem);
              @include res(width,3rem,(md:4rem,sm:5rem,xs:4rem));
            }
            button{
              flex-shrink: 0;
              background: transparent;
              border: none;
              cursor: pointer;
              @include res(width,.5rem,(md:.6rem,sm:.8rem));
              @include res(height,.5rem,(md:.6rem,sm:.8rem));
              .iconfont{
                @include res(font-size, .22rem,(md:.32rem,sm:.42rem,xs:.4rem));
              }
            }
          }
        }
      }
      .login{
        @include res(margin-left,.2rem,(xs:.1rem));
        .login-link{
          line-height: 1;
          display: block;
          background-color: $color-main;
          color: #fff;
          border-radius: 3px;
          transition: all .3s;
          @include res(padding,.1rem .2rem,(md:.15rem .2rem,xs:.15rem));
          @include res(font-size,.14rem,(md:.24rem,sm:.34rem,xs:.22rem));
          &:hover{
            background-color: $color-main-light;
          }
          .iconfont{
            @include res(display,null,(xs:none));
            @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.3rem));
            @include res(margin-right,.07rem);
          }
        }
        .logged{
          position: relative;
          &:hover{
            .logout{
              opacity: 1;
              visibility: visible;
            }
          }
          .username{
            line-height: 1;
            display: flex;
            align-items: center;
            border-radius: 3px;
            transition: all .3s;
            cursor: pointer;
            background-color: $color-main;
            color: #fff;
            @include res(padding,.1rem .2rem,(md:.15rem .2rem,xs:.15rem));
            @include res(font-size,.14rem,(md:.24rem,sm:.34rem,xs:.22rem));
            .iconfont{
              @include res(display,null,(xs:none));
              @include res(font-size,.18rem,(md:.28rem,sm:.38rem,xs:.3rem));
              @include res(margin-right,.07rem);
            }
            span{
              display: block;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              @include res(max-width,.7rem,(md:1.2rem,sm:1.8rem,xs:1rem));
            }
          }
          .logout{
            position: absolute;
            top: calc(100% + .01rem);
            right: 0;
            z-index: 1;
            min-width: 100%;
            white-space: nowrap;
            border: solid #bdbdbd;
            background-color: #fff;
            white-space: nowrap;
            border-radius: 5px;
            opacity: 0;
            visibility: hidden;
            transition: all .3s;
            overflow: hidden;
            @include res(border-width,.01rem,(md:1px));
            @include res(font-size,.16rem,(md:.26rem,sm:.32rem,xs:.28rem));
            div,a{
              display: block;
              line-height: 1.2;
              cursor: pointer;
              transition: background-color .3s;
              @include res(padding-left,.17rem);
              @include res(padding-right,.17rem);
              @include res(padding-top,.1rem);
              @include res(padding-bottom,.1rem);
              &:hover{
                background-color: #e9e9e9;
              }
            }
          }
        }
      }
      .hamburger{
        @include res(margin-left,.3rem,(xs:.15rem));
        @include res(display,none,(md:block));
      }
    }
  }
  .mob-header{
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 998;
    height: 100vh;
    overflow-x: hidden;
    background: #fff;
    background-size: cover;
    transition: all $anime-duration;
    @include res(padding-top, 2rem,(xs:1.8rem));
    @include res(padding-bottom, 1.5rem,(xs:.5rem));
    @include res(padding-left, 1rem,(xs:.3rem));
    @include res(padding-right, 1rem,(xs:.3rem));
    @include res(width,60%,(sm:80%,xs:100%));
    &.show{
      transform: translateX(-100%);
    }
    ul{
      height: 100%;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      li{
        border-bottom: 1px solid rgba(0,0,0,.1);
        &.active{
          .title .iconfont{
            transform: rotate(90deg);
          }
        }
        .title{
          align-items: center;
          justify-content: space-between;
          text-transform: uppercase;
          color: #000;
          @include res(line-height,1rem,(xs:1rem));
          @include res(padding-left,.3rem,(xs:.2rem));
          @include res(display,flex);
          @include res(font-size,.36rem,(sm:.46rem,xs:.36rem));
          a{
            flex-grow: 1;
          }
          .iconfont{
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            transition: all $anime-duration;
            transform-origin: center;
            @include res(display,none);
            @include res(font-size,.4rem,(xs:.34rem));
            @include res(width,1.4rem,(xs:1rem));
            @include res(height,1.4rem,(xs:1rem));
          }
        }
        .sub-nav{
          display: none;
          zoom: 1;
          @include res(font-size,.18rem,(md:.28rem,sm:.34rem,xs:.28rem));
          @include res(padding-left, .6rem,(xs:.3rem));
          @include res(padding-right, .6rem,(xs:0));
          @include res(padding-bottom,.3rem);
          &::after{
            content: '';
            display: block;
            clear: both;
          }
          a{
            display: block;
            color: #333;
            @include res(padding,.1rem 0);
            @include res(width,50%);
            @include res(float,left);
          }
        }
      }
    }
  }
  .menu-mask{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    position: fixed;
    top:0;
    left: 0;
    z-index: 997;
    display: none;
  }
}