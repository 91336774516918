@import "../../assets/styles/utils";

.pro-form{
  .common-form{
    align-items: flex-end;
    justify-content: space-between;
    border-radius: .12rem;
    background-color: #fff;
    @include res(display,flex,(sm:block));
    .left,.right{
      flex-shrink: 0;
      box-sizing: border-box;
      @include res(padding, .15rem .45rem,(sm:.45rem));
      @include res(width,49%,(sm:100%));
    }
    .left{
      @include res(padding-bottom,null,(sm:0rem));
      @include res(padding-right,.15rem,(sm:.45rem));
      .pro-img{
        display: flex;
        align-items: center;
        justify-content: center;
        @include res(min-height,2.4rem,(md:2.8rem,sm:0));
        img{
          opacity: .6;
          width: auto;
          height: auto;
          @include res(max-width,50%);
        }
      }
      .form-btn{
        margin-top: 0;
        button{
          background-color: #208eda;
          &:hover{
            background-color: #26a2f8;
          }
        }
      }
    }
    .right{
      @include res(padding-left,.15rem,(sm:.45rem));
      @include res(padding-top,.63rem,(md:.58rem,sm:0rem));
      @include res(margin-bottom, .2rem,(md:.25rem,sm:.3rem));
      .left-a,.right-a{
        flex-shrink: 0;
        @include res(width,49%,(xs:100%));
        .form-item{
          width: 100%;
        }
      }
      .left-a{
        .form-item:last-child{
          @include res(margin-bottom,0,(xs:.3rem));
        }
      }
      .right-a{
        .form-item{
          position: relative;
          // height: 100%;
          display: flex;
          flex-direction: column;
          // @include res(height,.6rem,(md:.75rem,sm:1.1rem,xs:.9rem));
          // @include res(margin-bottom, .2rem,(md:.25rem,sm:.3rem));
          textarea{
            flex-grow: 1;
            @include res(height,null,(xs:2rem));
          }
          .captcha-img{
            position: absolute;
            top: 1px;
            right: 1px;
            height: calc(100% - 2px);
            display: flex;
            align-items: center;
            cursor: pointer;
            img{
              display: block;
              width: auto;
              border-radius: .09rem;
              @include res(height,70%,(sm:100%));
            }
          }
          &:nth-child(1){
            @include res(height, calc(100% - 1.15rem),(md:calc(100% - 1.5rem),sm:calc(100% - 2rem),xs:auto));
          }
        }
      }
    }
  }
}