.pro-form .common-form {
  align-items: flex-end;
  justify-content: space-between;
  border-radius: .12rem;
  background-color: #fff;
  display: flex;
}

@media (max-width: 991px) {
  .pro-form .common-form {
    display: block;
  }
}

.pro-form .common-form .left, .pro-form .common-form .right {
  flex-shrink: 0;
  box-sizing: border-box;
  padding: 0.15rem 0.45rem;
  width: 49%;
}

@media (max-width: 991px) {
  .pro-form .common-form .left, .pro-form .common-form .right {
    padding: 0.45rem;
  }
}

@media (max-width: 991px) {
  .pro-form .common-form .left, .pro-form .common-form .right {
    width: 100%;
  }
}

.pro-form .common-form .left {
  padding-right: 0.15rem;
}

@media (max-width: 991px) {
  .pro-form .common-form .left {
    padding-bottom: 0rem;
  }
}

@media (max-width: 991px) {
  .pro-form .common-form .left {
    padding-right: 0.45rem;
  }
}

.pro-form .common-form .left .pro-img {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 2.4rem;
}

@media (max-width: 1024px) {
  .pro-form .common-form .left .pro-img {
    min-height: 2.8rem;
  }
}

@media (max-width: 991px) {
  .pro-form .common-form .left .pro-img {
    min-height: 0;
  }
}

.pro-form .common-form .left .pro-img img {
  opacity: .6;
  width: auto;
  height: auto;
  max-width: 50%;
}

.pro-form .common-form .left .form-btn {
  margin-top: 0;
}

.pro-form .common-form .left .form-btn button {
  background-color: #208eda;
}

.pro-form .common-form .left .form-btn button:hover {
  background-color: #26a2f8;
}

.pro-form .common-form .right {
  padding-left: 0.15rem;
  padding-top: 0.63rem;
  margin-bottom: 0.2rem;
}

@media (max-width: 991px) {
  .pro-form .common-form .right {
    padding-left: 0.45rem;
  }
}

@media (max-width: 1024px) {
  .pro-form .common-form .right {
    padding-top: 0.58rem;
  }
}

@media (max-width: 991px) {
  .pro-form .common-form .right {
    padding-top: 0rem;
  }
}

@media (max-width: 1024px) {
  .pro-form .common-form .right {
    margin-bottom: 0.25rem;
  }
}

@media (max-width: 991px) {
  .pro-form .common-form .right {
    margin-bottom: 0.3rem;
  }
}

.pro-form .common-form .right .left-a, .pro-form .common-form .right .right-a {
  flex-shrink: 0;
  width: 49%;
}

@media (max-width: 767px) {
  .pro-form .common-form .right .left-a, .pro-form .common-form .right .right-a {
    width: 100%;
  }
}

.pro-form .common-form .right .left-a .form-item, .pro-form .common-form .right .right-a .form-item {
  width: 100%;
}

.pro-form .common-form .right .left-a .form-item:last-child {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .pro-form .common-form .right .left-a .form-item:last-child {
    margin-bottom: 0.3rem;
  }
}

.pro-form .common-form .right .right-a .form-item {
  position: relative;
  display: flex;
  flex-direction: column;
}

.pro-form .common-form .right .right-a .form-item textarea {
  flex-grow: 1;
}

@media (max-width: 767px) {
  .pro-form .common-form .right .right-a .form-item textarea {
    height: 2rem;
  }
}

.pro-form .common-form .right .right-a .form-item .captcha-img {
  position: absolute;
  top: 1px;
  right: 1px;
  height: calc(100% - 2px);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.pro-form .common-form .right .right-a .form-item .captcha-img img {
  display: block;
  width: auto;
  border-radius: .09rem;
  height: 70%;
}

@media (max-width: 991px) {
  .pro-form .common-form .right .right-a .form-item .captcha-img img {
    height: 100%;
  }
}

.pro-form .common-form .right .right-a .form-item:nth-child(1) {
  height: calc(100% - 1.15rem);
}

@media (max-width: 1024px) {
  .pro-form .common-form .right .right-a .form-item:nth-child(1) {
    height: calc(100% - 1.5rem);
  }
}

@media (max-width: 991px) {
  .pro-form .common-form .right .right-a .form-item:nth-child(1) {
    height: calc(100% - 2rem);
  }
}

@media (max-width: 767px) {
  .pro-form .common-form .right .right-a .form-item:nth-child(1) {
    height: auto;
  }
}
